<template>
<section class="content-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h3>Nous contacter</h3>
                            <p>
                                Contrairement à la croyance populaire, Lorem Ipsum est simplement un texte aléatoire. Il a ses racines dans un morceau de littérature latine classique de 45 ans avant JC, vieux de maki.
                            </p>
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex flex-row align-items-top my-4">
                                        <i class="mdi mdi-send text-facebook icon-md"></i>
                                        <div class="ml-3">
                                        <h6 class="text-facebook">Adresse</h6>
                                        <p class="mt-2 text-muted card-text">60-49 Road 11378 New York</p>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row align-items-top my-4">
                                        <i class="mdi mdi-phone text-facebook icon-md"></i>
                                        <div class="ml-3">
                                        <h6 class="text-facebook">Téléphone</h6>
                                        <p class="mt-2 text-muted card-text">+65 11.188.888</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <!-- champ -->
                        <div class="col-md-6">
                            <h3>Laissez un commentaire</h3>
                            <p>Notre personnel vous rappellera plus tard et répondra à vos questions.</p>
                            <form class="forms-sample" @submit.prevent="handleSubmit">
                                <b-form-group label="Nom"  label-for="input1">
                                    <b-form-input type="text" id="input1" v-model="name" placeholder="Nom"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Email"  label-for="input1">
                                    <b-form-input type="email" id="input1" v-model="email" placeholder="Adresse Email"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Message"  label-for="input1">
                                    <b-form-textarea id="input10" placeholder="Message" v-model="message" :rows="3" :max-rows="6"></b-form-textarea>
                                </b-form-group>
                                <b-form-group>
                                    <vue-hcaptcha sitekey="fcfc5a4c-91e1-47a4-bb0e-0673c0b9ab26" @verify="verifyCaptcha"></vue-hcaptcha>
                                </b-form-group>
                                <b-button type="submit" variant="gradient-primary" class="mr-2">Envoyer</b-button>
                            </form>
                            This site is protected by hCaptcha and its
                            <a href="https://hcaptcha.com/privacy">Privacy Policy</a> and
                            <a href="https://hcaptcha.com/terms">Terms of Service</a> apply.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
const axios= require('axios').default
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
export default {
    name:'contact',
    components: {
        VueHcaptcha
    },
    data() {
        return {
            passcode: null,
            name:'',
            email:'',
            message:''
        }
    },
    methods: {
        verifyCaptcha (response) {
            console.log({response})
            this.passcode= response
        },
        handleSubmit() { 
                axios.post('contact',{
                    name: this.name,
                    email: this.email,
                    message: this.message,
                    passcode:this.passcode
                },{
                    withCredentials:true
                })
                .then(res=> {
                    console.log({res})
                    // location.reload()
                })
                .catch(err=> console.log(err))
            }
    }
}
</script>